.surroundingcontainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  .title {
    color: #9a7438;
    font-family: "Poppins", Sans-serif;
    font-weight: 400;
    font-size: 45px;
    padding: 25px 0 25px 0;
    @media (max-width: 768px) {
      font-size: 30px;
      text-align: center;
  }
  }
  .content {
    width: 100%;
    display: grid;

    grid-template-columns: auto auto auto auto;
    @media (max-width: 768px) {
      gap: 0;
      display: none;
      flex-direction: column;
    }
    .center {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      .imgcontent {
        width: 80%;;
        //height: 100%;
        @media (max-width: 768px) {
          width: 90%;
        }
      }
      .imgdes {
        height: 120px;
        margin-top: -60px;

        @media (max-width: 768px) {
          margin-bottom: -35px;
        }
        span {
          font-family: "Poppins", sans-serif;
          color: white;
          font-size: 20px;
          text-shadow: 1px 1px 15px black;
        }
      }
    }
  }
  .content1 {
    display: none;
    width: 90%;
    .slick-dots {
      bottom: 0px;
    }
    .slick-next {
      right: 25px;
    }
    .slick-prev {
      z-index: 1;
      left: 25px;
    }
    @media (max-width: 768px) {
      gap: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      .imgcontent {
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .imgdes {
        height: 120px;
        margin-top: -60px;
        text-align: center;
        @media (max-width: 768px) {
          margin-bottom: -35px;
        }
        span {
          font-family: "Poppins", sans-serif;
          color: white;
          font-size: 20px;
          text-shadow: 1px 1px 15px black;
        }
      }
    }
  }
}
