
.about {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 50px;

    background-color:#5A8365 ;
    padding-top: 20px;
    padding-bottom: 40px;
    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        margin-top: -1px;
        margin-bottom: -1px;
    }
    .container-about{
        width: 100%;
        display: flex;
        flex-direction: column;

        h1 {
            width: 90%;
            font-size: 30px;
            margin-left: 75px;
            font-weight: 400;
            color: #e7cb8c;
            @media (max-width: 768px) {
                width: 100%;
                text-align: center;
                font-size: 20px;
                margin-left: -5px;
            }
           

        }
        hr {
            width: 10%;
            margin-left: 80px;
            margin-top: -10px;
            @media (max-width: 768px) {
              width: 80%;
              margin-left: 35px;
          }
            
        }
        .container-desk {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        .desk {
            width: 80%;
            font-size: 16px;
            color: rgb(255, 255, 255);
            @media (max-width: 768px) {
                width: 90%;
                text-align: center;
            }
        }
    }
    .container-benefit {
        width: 100%;
        margin-top: 15px;margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        .benefit-list{
            width: 80%;

            .penawaranpoin {
                color: rgb(255, 255, 255);
                padding-top: 10px;
                span {
                    font-size: 15px;
                    color: rgb(255, 255, 255);
                }
            }
        }
    }
}

.slider-regia {
    width: 40%;
    margin-top: 60px;
    margin-right: 5%;
    @media (max-width: 768px){
      width: 90%;
      margin-right: 20px;
      margin-left: 20px;
      margin-top: -100px;
  }

    .slick-next::before,
    .slick-prev::before {
      font-family: "slick";
      font-size: 30px;
    }
    .slick-list {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    .slick-dots {
      position: absolute; /* Make dots absolute for positioning within slides */
      bottom: 10px; /* Adjust vertical positioning */
      left: 50%; /* Center dots horizontally */
      transform: translateX(-50%); /* Center dots precisely */
      z-index: 1; /* Ensure dots are above image content */
    }
    .slick-next {
      right: 25px;
    }
    .slick-prev {
      z-index: 1;
      left: 25px;
    }
    .slick-dots {
      li {
        margin: 0;
      }
    }
}
    .container-wa{
        width: 100%;
        margin-right: 500px;
        //margin-top: -10px;
        @media (max-width: 768px) {
          width: 0%;
          visibility: hidden;
        }
      .buttonpenawaran {
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 30px;
        margin-right: 50%;
        background-color: #C6B27F;
        width: 30%;
        
        padding: 12px 30px;
        border: 1px solid white; /* Add this line for black border */
        border-color: #C6B27F;
        border-radius: 20px;
        font-size: 16px;
        font-weight: light;
        cursor: pointer;
        display: inline-block;
        transition: background-color 0.3s ease, color 0.3s ease;
        @media (max-width: 768px) {
          justify-content: center;
          align-items: center;
          //margin-left: 15%;
          //margin-top: -30px;
          margin-bottom: -20px;
          width: 0%;
          visibility: hidden;
  
        }
        &:hover {
          background-color: transparent;
          color: white;
          border-color: white;
        }
        
      }
    }
}