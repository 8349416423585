.container-about {
    width: 100%;
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 25px;
    .container-deskripsi {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
            width: 95%;
        }
        .judulsumbog {
            width: 100%;
            h1 {
                font-size: 40px;
                font-weight: 400;
                color: rgb(152, 112, 56);
                @media (max-width: 768px) {
                    width: 95%;
                    text-align: center;
                    font-size: 30px;
                    margin-bottom: 30px;
                }
            }
        }
        .deskripsi-about {
            width: 100%;
            margin-top: -20px;
            padding-bottom: 20px;
            font-size: 15px;
            font-family: "poppins" sans-serif;
            @media (max-width: 768px) {
                width: 100%;
                text-align: center;
            }
        }
    }
    .rowcontainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
        .featurelist {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-left: 150px;
            margin-top: 20px;
            @media (max-width: 768px) {
                margin-left: 20px;;;
                margin-top: -10px;
            }
            .feature {
                padding-bottom: 10px;
                color: #C5B07E;
            }
            .container-wa{
                width: 100%;
                
                margin-top: 30px;
                @media (max-width: 768px) {
                  width: 0%;
                  visibility: hidden;
                }
              .buttonpenawaran {
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                //margin-top: 30px;
                //margin-left: 15%;
                background-color: #5A8365;
                width: 25%;
                
                padding: 12px 30px;
                border: 1px solid white; /* Add this line for black border */
                border-color: white;
                border-radius: 20px;
                font-size: 16px;
                font-weight: light;
                cursor: pointer;
                display: inline-block;
                transition: background-color 0.3s ease, color 0.3s ease;
                @media (max-width: 768px) {
                  justify-content: center;
                  align-items: center;
                  //margin-left: 15%;
                  //margin-top: -30px;
                  margin-bottom: -20px;
                  width: 0%;
                  visibility: hidden;
          
                }
                &:hover {
                  background-color: transparent;
                  color: #5A8365;
                  border-color: #5A8365;
                }
                
              }
            }
        }
        .logo-about {
            width: 100%;
            display: flex;
            justify-content: left;
            align-items: center;
            text-align: left;
            @media (max-width: 768px) {
                width: 100%;
                text-align: center;
                justify-content: center;
                margin-top: -50px;
            }
            img {
                width: 75%;
                border-radius: 20px;
                
            }
        }
    }
}