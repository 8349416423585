.background-container {
    background-image: url("../../Media/Sumbog_Desktop.webp"); /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    height: 100vh;
    border-radius: 0px 0px 50px 50px;
    @media (max-width: 768px) {
      /* Adjust breakpoint as needed */
      background-image: url("../../Media/Sumbog_Mobile.webp"); /* Replace with your mobile image URL */
      height: 90vh;
      
    }
  }